import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { push } from 'react-router-redux';

import { history } from '../../index';

import { answerUpdate, powercoinUpdate, updatePath } from '../../reducers/quiz';

class BasicQuestion extends Component {

  state = {
      isBlocking: true
  }

  componentDidMount(){
    if(this.props.questionTitle == "Do you usually switch the lights off when you leave the house?"){
      this.props.powercoinUpdate(0, 0);
    }
    this.unlisten = history.block((location, action) => {

      if(action == "POP"){
          this.props.powercoinUpdate(0, 0);
          return window.confirm("Are you sure you want to leave? You'll lose all current progress.");
      }
    });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.router.location !== this.props.router.location){
      this.setState({isBlocking: true});
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  submitQuestion(name, value, path){
    this.setState({isBlocking: false});
      this.props.powercoinUpdate(value, this.props.powercoinCount);
      this.props.answerUpdate(name, this.props.mainTopic, this.props.subTopic);
      this.props.updatePath(path);
  }

  renderOptions() {
    let options = [];
    if (this.props.options.length !== 0) {
      this.props.options.forEach(
        (item, index) => {
              options.push(
                <div
                key={index}
                className="option"
                tabIndex="0"
                onKeyPress={event => {
                     if (event.key === 'Enter') {
                       this.setState({isBlocking: false});
                       this.submitQuestion(item.name, item.value, item.nextPath);
                     }
                   }}
                onClick={event => {
                  event.preventDefault();
                  this.setState({isBlocking: false});
                  this.submitQuestion(item.name, item.value, item.nextPath)}}>
                  {item.name}
                </div>
              );
        }
      );
    }
    return options;
  }


  render() {
    return (
    <div className="question-container">
      <label className="main-title">{this.props.questionTitle}</label>
      <div className="option-container">
        {this.renderOptions()}
      </div>
    </div>

    );
  }
}

function mapStateToProps(state) {
  return {
    router: state.router,
    powercoinCount: state.quiz.powercoinCount
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      answerUpdate,
      powercoinUpdate,
      updatePath
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicQuestion);
