import React, { Component } from "react";

class FAQs extends Component {

  state = {
    faqObj: [
      {"title":"What does your score mean?", "desc": (<div>Your Power Score is a measure of your individual carbon footprint.</div>)},
      {"title":"How is your score calculated?", "desc": (<div>Your Power Score was calculated by pairing self reported lifestyle choices to their carbon footprint outcomes.<br/><br/> For example drinking tap water vs buying bottled water has differing impacts on your carbon footprint. In this case, choosing tap water would give you more Power Coin and cause a score increase to reflect that.</div>)},
      {"title": "How does blockchain make our solution possible?", "desc": (<div>A unique account per individual is provisioned on the PowerChain (Ethereum based blockchain. In future their account will be verified with their NYC ID)<br/><br/>This account also comes with a wallet for a user to be assigned, access and manage PowerCoin tokens.<br/><br/>PowerChain tokens are currently ERC-20 tokens on the Ethereum Blockchain. In future, we plan to use Clean Energy Generators among the contributors mining the tokens, to prevent carbon consumption for this purpose. <br/><br/>Data and behavior representing individuals’ carbon footprint tracked is kept up to date on the ledger<br/><br/>In future, consumers can trade surplus tokens with one another on PowerExchange and also redeem tokens to pay city taxes and earn tangible rewards.</div>)}
    ]
  }
  renderLeftFAQs() {
    let faqs = [];
    if (this.state.faqObj.length !== 0) {
      this.state.faqObj.forEach(
        (item, index) => {
            if(index == 0 || index == 1){
              faqs.push(
                <details key={item.title} className="">
                  <summary>{item.title}</summary>
                  <div className="dropdown-inner">{item.desc}</div>
                </details>
              );
            }
        }
      );
    }
    return faqs;
  }

  renderRightFAQs() {
    let faqs = [];
    if (this.state.faqObj.length !== 0) {
      this.state.faqObj.forEach(
        (item, index) => {
            if(index == 2){
              faqs.push(
                <details key={item.title} className="">
                  <summary>{item.title}</summary>
                  <div className="dropdown-inner">{item.desc}</div>
                </details>
              );
            }
        }
      );
    }
    return faqs;
  }


  render() {
      return (
        <div className="faq-container">
          <h2>Questions?</h2>
          <div className="faq-container-inner faq-container-left">
            {this.renderLeftFAQs()}
          </div>
          <div className="faq-container-inner faq-container-right">
          {this.renderRightFAQs()}
          </div>
        </div>
      );
  }
}


export default FAQs;
