import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import Cookies from 'js-cookie';

import { push } from 'connected-react-router';

import Web3 from 'web3';

const web3 = new Web3(Web3.givenProvider || "http://localhost:8545")

const initialState = {
  accounts: Cookies.get('walletAddress') ? Cookies.get('walletAddress'): '',
  tokens: 0,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_TOKENS":
      return {
        ...state
      };

      case "SET_WALLET_ADDRESS":
        Cookies.set('walletAddress', action.payload[0]);
        return {
          ...state,
          accounts: action.payload[0],
          error: null
        };

    case "METAMASK_DENIED":
      return {
        ...state,
        error: action.payload
      };

    case "METAMASK_NOT_INSTALLED":
        return {
          ...state,
          error: action.payload
        };


    default:
      return state;
  }
};


export const getAccounts = () => {
  return async dispatch => {
    console.log("gettingAccount");
    if (window.ethereum) {
         window.web3 = new Web3(window.ethereum);
         try {
             // Request account access if needed
             await window.ethereum.enable();
             // Acccounts now exposed
             let accounts = await web3.eth.getAccounts();
             window.ethereum.sendAsync({
               method: 'metamask_watchAsset',
               params: {
                 "type":"ERC20",
                 "options":{
                   "address": "0xc3C44BC68E4B9d4be3e1B9d698AD7Bcb22138C19",
                   "symbol": "PWRNYC",
                   "decimals": 18,
                   "image": "https://powerchain.nyc/powercoin-fav.png",
                 },
               },
             })
             if(Cookies.get("uid")){
               console.log(Cookies.get("uid"));

               firebase
                 .firestore()
                 .collection('users')
                 .doc(Cookies.get("uid"))
                 .update({"walletAddress": accounts[0]})
                 .then(() => {

                   dispatch({ type: "SET_WALLET_ADDRESS", payload: accounts});
                   dispatch(push("/account"));


                 });
             } else {
               dispatch({ type: "SET_WALLET_ADDRESS", payload: accounts});
               dispatch(push("/account"));

             }

         } catch (error) {
           dispatch({ type: "METAMASK_DENIED", payload: error});

         }
      } else if (window.web3) {
        window.web3 = new Web3(web3.currentProvider);
        // Acccounts always exposed
        let accounts = await web3.eth.getAccounts();

        if(Cookies.get("uid")){
          firebase
            .firestore()
            .collection('users')
            .doc(Cookies.get("uid"))
            .update({"walletAddress": accounts[0]})
            .then(() => {
              dispatch({ type: "SET_WALLET_ADDRESS", payload: accounts});
              dispatch(push("/account"));
            });
        } else {
          dispatch({ type: "SET_WALLET_ADDRESS", payload: accounts});
        }
    } else {
        dispatch({ type: "METAMASK_NOT_INSTALLED", payload: "Please install metamask to use this option." })
        console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
  };
};


export const addManualWallet = (walletAddress) => {
  return dispatch => {
      if(Cookies.get("uid")){
        firebase
          .firestore()
          .collection('users')
          .doc(Cookies.get("uid"))
          .update({"walletAddress": walletAddress})
          .then(() => {
            dispatch({ type: "SET_WALLET_ADDRESS", payload: walletAddress});
            dispatch(push("/account"));
          });
      } else {
        dispatch({ type: "SET_WALLET_ADDRESS", payload: walletAddress});
        dispatch(push(`/account`));
      }
        //Use push to change the url path
  };
};



export const createTransaction = () => {
  return async dispatch => {
    console.log("createTransaction");
    var transferPowercoins = firebase.functions().httpsCallable('transferPowercoins');
      transferPowercoins({walletAddress: Cookies.get('walletAddress'), powercoinCount: Cookies.get('powercoinCount')}).then(function(result) {
      // Read result of the Cloud Function.
      firebase
        .firestore()
        .collection('users')
        .doc(Cookies.get("uid"))
        .update({"tokensAllocated": true})
        .then(() => {
          dispatch({ type: "UPDATE_PROFILE_TOKEN_BOOL"});
          dispatch(push("/account"));
        });
      // ...
      });
  };
};
