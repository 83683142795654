import React, { Component } from 'react';

import QuizObject from "../../constants/QuizObject";

import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import CalculatingComponent from "./Calculating";
import BasicQuestion from "./BasicQuestion";

class QuizIndex extends Component {

  componentDidMount() {

  }

  renderQuizComponent() {

    for (var key in QuizObject) {
      if (QuizObject.hasOwnProperty(key) && this.props.match.params.quizIndex == key) {
        // console.log(this.props.match.params);
          switch(this.props.match.params.subpartIndex) {
            case 'a':
                return <BasicQuestion
                        questionTitle={QuizObject[key]['a']['questionTitle']}
                        options={QuizObject[key]['a']['options']}
                        nextPath={QuizObject[key]['a']['nextPath']}
                        mainTopic={key}
                        subTopic='a' />;
            case 'b':
              return <BasicQuestion
                    questionTitle={QuizObject[key]['b']['questionTitle']}
                    options={QuizObject[key]['b']['options']}
                    nextPath={QuizObject[key]['b']['nextPath']}
                    mainTopic={key}
                    subTopic='b' />;
            case 'c':
              return <BasicQuestion
                    questionTitle={QuizObject[key]['c']['questionTitle']}
                    options={QuizObject[key]['c']['options']}
                    nextPath={QuizObject[key]['c']['nextPath']}
                    mainTopic={key}
                    subTopic='c' />;
            case 'd':
              return <BasicQuestion
                    questionTitle={QuizObject[key]['d']['questionTitle']}
                    options={QuizObject[key]['d']['options']}
                    nextPath={QuizObject[key]['d']['nextPath']}
                    mainTopic={key}
                    subTopic='d'/>;
            case 'e':
              return <BasicQuestion
                    questionTitle={QuizObject[key]['e']['questionTitle']}
                    options={QuizObject[key]['e']['options']}
                    nextPath={QuizObject[key]['e']['nextPath']}
                    mainTopic={key}
                    subTopic='e' />;
            default :
                return (
                  <div className="not-found">
                    <h1 className="main-title">There's nothing here!</h1>
                    <Link className="btn btn-primary btn-orange" to="/">Home</Link>
                  </div>
                )
          }
      }
    }
  }

  renderQuizNav() {
    let navBlocks = [];
      let stopProgress = false;
      for (var key in QuizObject) {
          if(stopProgress){
            navBlocks.push(
              <div key={key} className="quiz-nav-block">
              </div>
            );
          } else {
            if(this.props.match.params.quizIndex == key){
              navBlocks.push(
                <div key={key} className="quiz-nav-block active">
                <span className="quiz-nav-title">{QuizObject[key]['displayName']}</span>
                </div>
              );
            } else {
              navBlocks.push(
                <div key={key} className="quiz-nav-block active">
                </div>
              );
            }
          }
          if(this.props.match.params.quizIndex == key){
            stopProgress = true;
          }
      }
    return navBlocks;
  }


  render() {
    return (
    <div className="container quiz-container">
      <div className="main-content">
        {this.renderQuizComponent()}
      </div>
      <div className="quiz-nav">
        {this.renderQuizNav()}
      </div>
    </div>

    );
  }
}


export default QuizIndex;
