import React, { Component } from 'react';

import { Helmet } from "react-helmet";

import { connect } from "react-redux";

import SocialModule from "./SocialModule";
import FAQs from "./FAQ";

class Completed extends Component {


  render() {
    let powerval = (this.props.powerscore/1000).toFixed(2) == this.props.powerscore/1000 ? this.props.powerscore/1000 :  (this.props.powerscore/1000).toFixed(2) ;
    return (
    <div className="complete-container">
      <Helmet>
        <title>Thank you for participating | PowerChain</title>
        <meta
          name="description"
          content="This is just a sample description for the homepage"
        />
      </Helmet>
      <div className="main-content">
        <div className="score-container">
        <span className="perpetual-name">Perpetual</span>
        <div className="score-inner-container">
          <span className="powerscore-text">Power<br/>Score</span>
          <div className="score-amount">
            <img src="/assets/PowerScoreMeter.png" alt=""/>
            <span className="score">{powerval}</span>
          </div>
        </div>
      </div>
        <div className="next-steps">
          <h1>Three ways to improve your carbon footprint and your score!</h1>
          <h2>Follow these steps and reassess your Power Score at powerchain.nyc up to twice a month.</h2>
          <div className="step has-btn">
            <div className="step-num">1</div>
            <div className="step-desc">
              <span>The New York State Energy Research and Development Authority outlines steps to minimize your carbon footprint. </span>
              <a className="btn small btn-orange" href="https://www.nyserda.ny.gov/Residents-and-Homeowners/Reduce-Your-Footprint" target="_blank" rel="noopener noreferrer">Visit</a>
            </div>
          </div>
          <div className="step has-btn">
            <div className="step-num">2</div>
            <div className="step-desc">
              <span>Visit NYC Environment Agency sites to learn more about citywide sustainability initiatives.</span>
              <a className="btn small btn-orange" href="https://www1.nyc.gov/nyc-resources/agencies.page" target="_blank" rel="noopener noreferrer">Visit</a>
            </div>
          </div>
          <div className="step">
            <div className="step-num">3</div>
            <span>Follow NYC Environment Agencies on social media to stay in the loop on upcoming announcements.</span>
          </div>
        </div>
        <SocialModule />
        <FAQs />
        <div className="learn-more-container relative">
          <div className="learn-more-text">
            <h2>Thanks for participating!</h2>
            <span>Follow the links below to learn about more ways you can reduce your carbon footprint.</span>
          </div>
          <a target="_blank" rel="noopener noreferrer" href="https://www.nyserda.ny.gov/Residents-and-Homeowners/Reduce-Your-Footprint" className="learn-more-icon large">
            <img src="/assets/CleanChoiceLogo.png" alt="Clean Choice NYC"/>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.nycgovparks.org/opportunities/volunteer" className="learn-more-icon">
            <img src="/assets/NYCParks.png" alt="NYC Parks logo"/>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.coned.com/en/save-money/energy-saving-programs" className="learn-more-icon large">
            <img src="/assets/ConEd.png" alt="ConEd logo"/>
          </a>
        </div>
      </div>
    </div>

    );
}
}

function mapStateToProps(state) {
  return {
    router: state.router,
    powerscore: state.quiz.powercoinCount
  };
}

export default connect(
  mapStateToProps,
  null
)(Completed);
