import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { contactMessage } from '../reducers/user';

class ContactIndex extends Component {

  onSubmit(e){
    e.preventDefault();
    let contact = e.target.querySelector("textarea[name='contact-field']").value;
    this.props.contactMessage(contact);
  }

  renderFieldOrThankYou() {
    console.log(this.props.sent);
    if(!this.props.sent){
      return (
        <form onSubmit={this.onSubmit.bind(this)} className="">
          <label className="contact-label">Let us know what you think!</label>
          <textarea
            name="contact-field"
            id="contact"
            required
          />
          <button className="btn btn-primary btn-orange small">Send it</button>
        </form>
      )
    } else {
      return <h4>Thank you, we'll get back to you soon!</h4>;
    }
  }

  render() {
    return (
    <div className="contact-container">
      <div className="main-content">
        <Helmet>
          <title>PowerChain</title>
          <meta
            name="description"
            content="This is just a sample description for the homepage"
          />
        </Helmet>
        <div className="contact-inner">
          {this.renderFieldOrThankYou()}
          <div className="small-cta">
            <div>call us</div>
            <a href="tel:2129041497">(212) 904-1497</a>
          </div>
          <div className="small-cta">
            <div>write us</div>
            <a href="mailto:powerchain@perpetualny.com">powerchain@perpetualny.com</a>
          </div>
          <div className="small-cta">
            <div>see us</div>
            <a href="https://blockchain.perpetualny.com/">https://blockchain.perpetualny.com/</a>
          </div>
        </div>
      </div>
      <div className="skyline">
        <img src="/assets/ContactUsSkylineCoin.svg"/>
      </div>
    </div>

    );
  }
}

const mapStateToProps = state => ({
  sent: state.user.contactSent
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      contactMessage
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactIndex);
