import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import Cookies from 'js-cookie';

import { push } from 'connected-react-router';


const initialState = {
  err: {},
  isLoggedIn: false,
  user: null,
  userId: Cookies.get('uid') ? Cookies.get('uid') : null,
  profile: {},
  contactSent: false,
  leaderboard: []
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID
};

firebase.initializeApp(firebaseConfig);

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      return {
        ...state,
        isLoggedIn: false,
        errors: action.payload,
        user: null
      };
    case "CREATE_USER_ERROR":
      return {
        ...state,
        err: action.payload,
        user: null
      };

    case "LOADING":
      return {
        ...state,
        loading: true
      }

    case "CONTACT_SENT":
      return {
        ...state,
        contactSent: true
      }

    case "LOGIN_SUCCESS":
      return {
        ...state,
        isLoggedIn: true,
        message: null,
        errors: null
      };

      case "SET_USER":
        Cookies.set("walletAddress", action.payload.profile.walletAddress);
        return {
          ...state,
          isLoggedIn: true,
          user: action.payload.user,
          profile: action.payload.profile,
          userId: action.payload.userId
        };
      case "UPDATE_PROFILE_TOKEN_BOOL":
        return {
          ...state,
          profile: {
            ...state.profile,
            tokensAllocated: true
          }
        }

      case "LOGGED_OUT":
        Cookies.remove('uid');
        Cookies.remove('profile');
        Cookies.remove('powercoinCount');
        Cookies.remove('currentPath');

        return {
          ...state,
          isLoggedIn: false,
          user: null,
          profile: null
        };

      case "LEADERBOARD_FETCHED":
        return {
          ...state,
          leaderboard: action.payload
        }


    default:
      return state;
  }
};

/*********************************************************************
 ** Function userSignUpRequest
 **
 ** Allows the user to create an account. This function fails with an
 ** error if the user provides an incorrect registration key, which is
 ** defined in the environment variables for the individual app. It will
 ** send out an email verification notification after creating the
 ** account within firebase.
 **
 ** @param: email = The email supplied by the user.
 ** @param: password = The password supplied by the user.
 ** @param: firstName = The first name supplied by the user.
 ** @param: lastName = The last name supplied by the user.
 **
 *********************************************************************/

export const userSignUpRequest = (
  email,
  password,
  firstName,
  lastName,
  powercoinCount,
  answers,
  currentPath
) => {
  return dispatch => {
    dispatch({
      type: "CREATE_USER_REQUEST"
    });

      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(creds => {
          let setData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            powercoinCount: parseInt(powercoinCount) + 1000,
            answers: answers,
            currentPath: currentPath
          };
          console.log(creds);

          firebase
            .firestore()
            .collection('users')
            .doc(creds.user.uid)
            .set(setData)
            .then(() => {
              dispatch({ type: "CREATE_USER_SUCCESS", profile: setData, user: creds.user });
              dispatch(push('/register/wallet-options'));
            });
        })
        .catch(err => {
          console.log(err);
          dispatch({ type: "CREATE_USER_ERROR", payload: err });
        });
  };
};

/*********************************************************************
 ** Function userLoginRequest
 **
 ** Called when the user attempts to log in. The function will check
 ** the auth creds against firebase, and update the currentUser object
 ** if the creds match. The function can also be used to update the
 ** user's information in the database, to ensure that newly added
 ** variables are not left undefined before use.
 **
 ** @param: email = The email supplied by the user.
 ** @param: password = The password supplied by the user.
 **
 *********************************************************************/

export const userLoginRequest = (email, password) => {
  return (dispatch, getState) => {
    //dispatch({
    //type: LOGIN_USER_START
    //});

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(resp => {
        let userId = resp.user.uid;
        console.log(resp.user);
        Cookies.set('uid', resp.user.uid);

        firebase
          .firestore()
          .collection('users')
          .doc(userId)
          .get()
          .then(doc => {
            console.log(doc);
            console.log(doc.exists);
            if(doc.exists){
              dispatch({
                type: "SET_USER",
                payload: { user: resp.user, profile: doc.data(), userId: userId }
              });
              dispatch({ type: "LOGIN_SUCCESS"});
              dispatch(push('/account'));
            }
          });
        })
        .catch(err => {
          console.log(err);
          dispatch({ type: "LOGIN_ERROR", payload: err });
        });
  };
};


/*********************************************************************
 ** Function userLogOutRequest
 **
 ** Called when the user attempts to log out. Will sign the user out
 ** of firebase's authentication. The LOGGED_OUT event will also ensure
 ** that all of the cookies are wiped.
 **
 ** @param: email = The email supplied by the user.
 ** @param: password = The password supplied by the user.
 **
 *********************************************************************/

export const userLogOutRequest = (email, password) => {
  return dispatch => {
    firebase
      .auth()
      .signOut()
      .then(resp => {
        console.log(resp);
        dispatch({ type: "LOGGED_OUT" });
        dispatch(push('/'));
      })
  };
};

/*********************************************************************
 ** Function fetchCurrentUser
 **
 ** Called in the app constructor, this function is used to fetch the
 ** latest user data from firebase. It can also be used to ensure all
 ** currently used variables are defined in the database.
 **
 *********************************************************************/
export const fetchCurrentUser = () => {
  return dispatch => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        let userId = user.uid;
        console.log(user);
        Cookies.set('uid', user.uid);

        firebase
          .firestore()
          .collection('users')
          .doc(userId)
          .get()
          .then(doc => {
            console.log(doc);
            console.log(doc.exists);
            if(doc.exists){
              dispatch({
                type: "SET_USER",
                payload: { user, profile: doc.data(), userId: userId }
              });
              if(doc.data().powercoinCount){
                dispatch({type: "POWERCOIN_UPDATE", payload: doc.data().powercoinCount});
              }
            }
          });
      } else {
        Cookies.remove('uid');
        Cookies.remove('profile');
      }
    });
  };
};


/*********************************************************************
 ** Function powercoinUpdate
 **
 ** @param: amount = The amount to update by
 ** @param: current = The current powercoin amount
 **
 *********************************************************************/

export const contactMessage = (message) => {
  return (dispatch, getState) => {
    let contactObj = {"message": message};
    if(getState().user.profile.firstName){
      contactObj['firstName'] = getState().user.profile.firstName;
    }
    if(getState().user.profile.lastName){
      contactObj['lastName'] = getState().user.profile.lastName;
    }
    if(getState().user.profile.email){
      contactObj['email'] = getState().user.profile.email;
    } else {
      contactObj['user'] = "Not logged in.";
    }
        firebase
          .firestore()
          .collection('contactRequests')
          .add(contactObj)
          .then(() => {
            dispatch({ type: "CONTACT_SENT", payload: true });
          }).catch(err => {
            dispatch({ type: "CONTACT_ERROR", payload: false });
          });
  };
};


/*********************************************************************
 ** Function powercoinUpdate
 **
 ** @param: amount = The amount to update by
 ** @param: current = The current powercoin amount
 **
 *********************************************************************/

export const getLeaderboard = () => {
  return dispatch => {
        firebase
          .firestore()
          .collection('users')
          .where("powercoinCount", ">=", 0)
          .orderBy("powercoinCount", "desc")
          .limit(10)
          .get()
          .then((querySnapshot) => {
            let users = [];
            querySnapshot.forEach(function(doc) {
              let data = doc.data();
              data['uid'] = doc.id;
              users.push(data);
            })
            dispatch({ type: "LEADERBOARD_FETCHED", payload: users });
          }).catch(err => {
            dispatch({ type: "LEADERBOARD_ERROR", payload: err });
          });
  };
};
