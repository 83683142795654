/**
** The QuizObject is used to generate the questions for our initial survey. The top level keys are the main topics for each section of the quiz. Within those values are objects for each question in the section. The keys for each of these objects equate to the subpath within the top level object, so the path will be /topic-key/subtopic-key/ . The pathName parameter should specify this path. The questionTitle is the display title for the question. The options parameter has an array for multiple choice answers. Each option should have a name, value, and specify where the user should go if the answer is selected in the nextPath parameter.
**/
export default  {
  "electricity": {
    "a":  {
        "pathName": "/electricity/a",
        "questionTitle":"Do you usually switch the lights off when you leave the house?",
        "options":
          [
            {"name": "Yes", "value": 500, "nextPath": "/electricity/b"},
            {"name": "No", "value": 250, "nextPath": "/electricity/b"}
          ]
      },
    "b": {
        "pathName": "/electricity/b",
        "questionTitle":"Do you use smart power strips in your home?",
        "options":
          [
            {"name": "Yes", "value": 500, "nextPath": "/water/a"},
            {"name": "No", "value": 250, "nextPath": "/water/a"}
          ]
      },
    "displayName": "Electricity"
  },
  "water": {
    "a": {
        "pathName": "/water/a",
        "questionTitle":"Do you use a dishwasher?",
        "options":
          [
            {"name": "Yes", "value": 500, "nextPath": "/water/c"},
            {"name": "No", "value": 0, "nextPath": "/water/b"}
          ]
      },
    "b": {
        "pathName": "/water/b",
        "questionTitle":"Do you usually wash with warm or cold water?",
        "options":
          [
            {"name": "Warm", "value": 250, "nextPath": "/water/c"},
            {"name": "Cold", "value": 500, "nextPath": "/water/c"}
          ]
      },
      "c": {
          "pathName": "/water/c",
          "questionTitle":"Do you prefer drinking tap or bottled water?",
          "options":
            [
              {"name": "Tap", "value": 500, "nextPath": "/temp-control/a"},
              {"name": "Bottled", "value": 250, "nextPath": "/temp-control/a"}
            ]
        },
      "displayName": "Water"
  },
  "temp-control": {
    "a": {
        "pathName": "/temp-control/a",
        "questionTitle":"Do you use a programmable or smart thermostat?",
        "options":
          [
            {"name": "Yes", "value": 500, "nextPath": "/temp-control/b"},
            {"name": "No", "value": 0, "nextPath": "/temp-control/c"}
          ]
      },
    "b": {
        "pathName": "/temp-control/b",
        "questionTitle":"Have you ever weatherized your house by sealing any air leaks or insulating areas of your home?",
        "options":
          [
            {"name": "Yes", "value": 500, "nextPath": "/temp-control/c"},
            {"name": "No", "value": 250, "nextPath": "/temp-control/c"}
          ]
      },
    "c": {
        "pathName": "/temp-control/c",
        "questionTitle":"Do you leave your AC/Heating on when you leave the house?",
        "options":
          [
            {"name": "Most of the time", "value": 125, "nextPath": "/commute/a"},
            {"name": "Sometimes", "value": 250, "nextPath": "/commute/a"},
            {"name": "Never", "value": 500, "nextPath": "/commute/a"}
          ]
      },
      "displayName": "Temperature"

  },
  "commute": {
    "a": {
        "pathName": "/commute/a",
        "questionTitle":"How do you usually commute?",
        "options":
          [
            {"name": "Walking", "value": 500, "nextPath": "/commute/b"},
            {"name": "Biking", "value": 500, "nextPath": "/commute/b"},
            {"name": "Public Transport", "value": 250, "nextPath": "/commute/c"},
            {"name": "Car", "value": 125, "nextPath": "/programs/a"}
          ]
      },
    "b": {
        "pathName": "/commute/b",
        "questionTitle":"Do you use a bikeshare service?",
        "options":
          [
            {"name": "Yes", "value": 0, "nextPath": "/programs/a"},
            {"name": "No", "value": 0, "nextPath": "/programs/a"}
          ]
      },
    "c": {
        "pathName": "/commute/c",
        "questionTitle":"Which of the following is your primary method of transportation?",
        "options":
          [
            {"name": "Subway", "value": 0, "nextPath": "/programs/a"},
            {"name": "Bus", "value": 0, "nextPath": "/programs/a"},
            {"name": "Ferry", "value": 0, "nextPath": "/programs/a"}
          ]
      },
      "displayName": "Commute"
  },
  "programs": {
    "a": {
        "pathName": "/programs/a",
        "questionTitle":"Have you ever participated in any NYC Gov programs?",
        "options":
          [
            {"name": "Yes", "value": 1000, "nextPath": "/programs/b"},
            {"name": "No", "value": 250, "nextPath": "/programs/d"}
          ]
      },
    "b": {
        "pathName": "/programs/b",
        "questionTitle":"Have you ever volunteered for GreeNYC?",
        "url":"https://www1.nyc.gov/site/greenyc/volunteer/volunteer.page",
        "options":
          [
            {"name": "Yes", "value": 1000, "nextPath": "/programs/c"},
            {"name": "No", "value": 250, "nextPath": "/programs/c"}
          ]
      },
    "c": {
        "pathName": "/programs/c",
        "questionTitle":"Have you taken the NYC Zero Waste Pledge?",
        "url":"https://www1.nyc.gov/assets/dsny/site/our-work/zero-waste",
        "options":
          [
            {"name": "Yes", "value": 1000, "nextPath": "/calculate"},
            {"name": "No", "value": 250, "nextPath": "/calculate"}
          ]
      },
      "d": {
          "pathName": "/programs/d",
          "questionTitle":"Have you ever heard of GreeNYC?",
          "url":"https://www1.nyc.gov/site/greenyc/volunteer/volunteer.page",
          "options":
            [
              {"name": "Yes", "value": 500, "nextPath": "/programs/e"},
              {"name": "No", "value": 250, "nextPath": "/programs/e"}
            ]
        },
      "e": {
          "pathName": "/programs/e",
          "questionTitle":"Have you ever heard of NYC Zero Waste Pledge?",
          "url":"https://www1.nyc.gov/assets/dsny/site/our-work/zero-waste",
          "options":
            [
              {"name": "Yes", "value": 500, "nextPath": "/calculate"},
              {"name": "No", "value": 250, "nextPath": "/calculate"}
            ]
        },
      "displayName": "Programs"
  }
}
