import React from 'react';

const SocialModule = () => (
  <div className="social-module">
    <div className="social-column">
      <h4>@greenyc</h4>
      <div className="social-inner">
        <a className="social-icon" href="https://www.facebook.com/GreenNYC/" target="_blank" rel="noopener noreferrer">
          <img src="/assets/FBLogo.png"/>
        </a>
          <a className="social-icon" href="https://www.instagram.com/greenyc/?hl=en" target="_blank" rel="noopener noreferrer">
            <img src="/assets/InstaLogo.png"/>
          </a>
          <a className="social-icon" href="https://twitter.com/greenyc?lang=en" target="_blank" rel="noopener noreferrer">
            <img src="/assets/TwitterLogo.png"/>
          </a>
      </div>
    </div>
    <div className="social-column">
      <h4>@NYClimate</h4>
      <div className="social-inner">
          <a className="social-icon" href="https://twitter.com/NYClimate" target="_blank" rel="noopener noreferrer">
            <img src="/assets/TwitterLogo.png"/>
          </a>
      </div>
    </div>
    <div className="social-column">
      <h4>@nyserta</h4>
      <div className="social-inner">
          <a className="social-icon" href="https://www.facebook.com/NYSERDA/" target="_blank" rel="noopener noreferrer">
            <img src="/assets/FBLogo.png"/>
          </a>
          <a className="social-icon" href="https://www.instagram.com/nyserda/?hl=en" target="_blank" rel="noopener noreferrer">
            <img src="/assets/InstaLogo.png"/>
          </a>
          <a className="social-icon" href="https://twitter.com/nyserda" target="_blank" rel="noopener noreferrer">
            <img src="/assets/TwitterLogo.png"/>
          </a>
      </div>
    </div>
  </div>
)

export default SocialModule;
