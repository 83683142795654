import React, { Component } from 'react';

import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { push } from 'react-router-redux';

import { bindActionCreators } from 'redux';

import { getLeaderboard } from '../../reducers/user';



class Leaderboard extends Component {

  state = {
    leaderboardWithUser: []
  }

  componentDidMount() {
    this.props.getLeaderboard();
  }

  componentDidUpdate(prevProps){
    if(prevProps.leaderboard !== this.props.leaderboard || prevProps.userUid !== this.props.userUid){
      let newLeaderboard = [];
      if(this.props.userUid){
        let foundUser = false;
        this.props.leaderboard.forEach((item, index) =>{
          if(item.uid === this.props.userUid){
            item['isUser'] = true;
            foundUser = true;
            newLeaderboard.push(item);
          } else {
            item['isUser'] = false;
            newLeaderboard.push(item);
          }
        });
        if(!foundUser){
          let userRow = {"uid": this.props.userUid ? this.props.userUid: 0, "powercoinCount": this.props.powercoinCount ? this.props.powercoinCount : 0, "firstName": this.props.firstName ? this.props.firstName : "You", "lastName": this.props.lastName ? this.props.lastName : "", "isUser": true};
          newLeaderboard.push(userRow);
        }
      } else {
        let userRow = {"uid": 0, "powercoinCount": this.props.powercoinCount ? this.props.powercoinCount: 0, "firstName": "You", "lastName": "", "isUser": true};
        newLeaderboard = this.props.leaderboard;
        newLeaderboard.push(userRow);
      }
      newLeaderboard.sort((a,b) => {return b.powercoinCount - a.powercoinCount});
      console.log(newLeaderboard);
      this.setState({leaderboardWithUser: newLeaderboard});
    }
  }

  renderLeaderboard() {
    let leaders = [];
    if (this.state.leaderboardWithUser.length !== 0) {
      this.state.leaderboardWithUser.forEach(
        (item, index) => {
              leaders.push(
                <div key={item.uid} className={`leaderboard-row rank-${index+1} ${item.isUser ? "is-user": ""}`}>
                  <div className="leaderboard-cell ranking-container">
                    <span className="rank-num">
                      {index+1}
                    </span>
                    <div className={`rank-badge rank-${index+1}`}>
                    </div>
                  </div>
                  <div className="leaderboard-cell leader-name">
                    <span className="leader-span">{item.firstName} {item.lastName}</span>
                    <div className="token-amount">
                      <img src="/powercoin-fav.png" alt="PowerCoin" />
                      {item.powercoinCount}
                    </div>
                  </div>
                </div>
              );
        }
      );
    } else {
      leaders.push(
        <div key="loading" className="leaderboard-row"><h4>Loading...</h4></div>
      )
    }
    return leaders;
  }

  renderNextSteps() {
    if(!this.props.userUid){
      return (
        <div className="next-steps">
          <h4><span className="bold">create an account</span> to get your
            <br/>name on the table and <span className="bold">earn more coins</span></h4>
            <div className="token-container">
              <img src="/powercoin-fav.png" alt="PowerCoin" />
              <span className="token-text">+1000</span>
            </div>
            <div
            className="btn btn-orange"
            tabIndex="0"
            onKeyPress={event => {
                 if (event.key === 'Enter') {
                   this.props.goToCreateAccount();
                 }
               }}
            onClick={event => {
              event.preventDefault();
              this.props.goToCreateAccount()}}>
              Create Account
            </div>
        </div>
      )
    } else {
      return null;
    }
  }

  render() {
    return (
    <div className="container leaderboard-container">
    <Helmet>
      <title>Leaderboard | PowerChain</title>
      <meta
        name="description"
        content="This is just a sample description for the homepage"
      />
    </Helmet>
      <div className="main-content">
        <h1 className="leaderboard-h1">Power Board</h1>
        <div className="leaderboard">
          {this.renderLeaderboard()}
        </div>
        <div className="next-steps-container">
          {this.renderNextSteps()}
        </div>
      </div>
    </div>

    );
  }
}

function mapStateToProps(state) {
  return {
    router: state.router,
    leaderboard: state.user.leaderboard,
    powercoinCount: state.quiz.powercoinCount,
    userUid: state.user.userId,
    firstName: state.user.firstName,
    lastName: state.user.lastName
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLeaderboard,
      goToCreateAccount: () => push("/register")

    },
    dispatch
  );


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Leaderboard);
