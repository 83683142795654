import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router';

import user from './user';
import quiz from './quiz';
import web3 from './web3';


export default (history) => combineReducers({
  router: connectRouter(history),
  quiz: quiz,
  user: user,
  web3: web3
})
