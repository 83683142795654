import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Helmet } from "react-helmet";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { userSignUpRequest } from '../../reducers/user';

class Register extends Component {
  state = {
    showLabel1: true,
    showLabel2: true,
    showLabel3: true,
    showLabel4: true
  }

  onSubmit(e) {
    e.preventDefault();
    let firstName = e.target.querySelector("input[name='fName']").value;
    let lastName = e.target.querySelector("input[name='lName']").value;
    let email = e.target.querySelector("input[name='email']").value;
    let password = e.target.querySelector("input[name='password']").value;
    this.props.userSignUpRequest(email, password, firstName, lastName, this.props.powercoinCount, this.props.answers, this.props.currentPath);
  }


  render() {
    return (
      <div className="container login-container">
      <Helmet>
        <title>Register | PowerChain</title>
        <meta
          name="description"
          content="This is just a sample description for the homepage"
        />
      </Helmet>
        <div className="login">
          <h1 className="text-center register-title">Register</h1>
            <form onSubmit={this.onSubmit.bind(this)}>
            <div className="login-fieldgroup">
              <label style={{"display": this.state.showLabel1 ? "block":"none"}}>First Name</label>
              <input
                type="text"
                name="fName"
                id="firstName"
                required
                onChange={e => {
                  if(e.currentTarget.value){
                    this.setState({...this.state, showLabel1: false});
                  } else {
                    this.setState({...this.state, showLabel1: true});
                  }
                }}
              />
            </div>
            <div className="login-fieldgroup">
              <label style={{"display": this.state.showLabel2 ? "block":"none"}}>Last Name</label>
              <input
                type="text"
                name="lName"
                id="lastName"
                required
                onChange={e => {
                  if(e.currentTarget.value){
                    this.setState({...this.state, showLabel2: false});
                  } else {
                    this.setState({...this.state, showLabel2: true});
                  }
                }}
              />
            </div>
              <div className="login-fieldgroup">
                <label style={{"display": this.state.showLabel3 ? "block":"none"}}>Email Address</label>
                <input
                  type="email"
                  name="email"
                  id="exampleEmail"
                  required
                  onChange={e => {
                    if(e.currentTarget.value){
                      this.setState({...this.state, showLabel3: false});
                    } else {
                      this.setState({...this.state, showLabel3: true});
                    }
                  }}
                />
              </div>
              <div className="login-fieldgroup">
                <label style={{"display": this.state.showLabel4 ? "block":"none"}}>Password</label>
                <input
                  type="password"
                  name="password"
                  id="examplePassword"
                  required
                  onChange={e => {
                    if(e.currentTarget.value){
                      this.setState({...this.state, showLabel4: false});
                    } else {
                      this.setState({...this.state, showLabel4: true});
                    }
                  }}
                />
              </div>
              <div className="sign-in text-center">
                  <button className="btn btn-primary btn-orange">Sign me up</button>
              </div>
                  <div className="text-center">
                    <Link className="register-link" to='/account'>Sign In</Link>
                  </div>
            </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  powercoinCount: state.quiz.powercoinCount,
  answers: state.quiz.answers,
  currentPath: state.quiz.currentPath
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      userSignUpRequest
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
