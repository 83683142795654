import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class PageIndex extends Component {


  render() {
    return (
    <div>
      <div className="main-content">
        <Helmet>
          <title>PowerChain</title>
          <meta
            name="description"
            content="This is just a sample description for the homepage"
          />
        </Helmet>
        <div className="">
          <h1 className="main-title">Welcome to Power Chain!</h1>
          <h2 className="subtitle">An incentive driven air purifier</h2>
          <p>
            Perpetual is creating a blockchain-based solution that measures individual and public sector service energy consumption against one another
            <br/><br/>
            Power Chain will allow New York City residents to securely track their individual carbon footprint by capturing usage in a unique “Power Score”.
          </p>
          <h3 className="main-title">Are you ready to discover your <br/>Power Score? </h3>
          <Link className="btn btn-primary btn-orange" to={this.props.quiz.currentPath == "/quiz/calculate" ? '/next-steps' : this.props.quiz.currentPath}>Born Ready</Link>
        </div>
      </div>
      <div className="skyline">
        <img src="/assets/SkylineCoin.svg"/>
      </div>
    </div>

    );
  }
}

export default PageIndex;
