import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { push } from 'connected-react-router';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class CalculatingComponent extends Component {

  state = {
    timer: 0,
    intervalId: 0
  }

  componentDidMount() {
    this.setState({
      intervalId: setInterval(this.updateTimer.bind(this), 200)
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    console.log('interval cleared');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.timer > 110){
      this.props.scoreUpdated();
    }
  }

  updateTimer() {
    this.setState({
      timer: this.state.timer + 5
    });
  }

  render() {
    return (
      <div className="container quiz-container">
        <div className="main-content">
          <div className="loading-container">
            <div className="loading-text">
              <h1 className="main-title">Please wait...</h1>
              <p>Your predicted Power Score is being tabulated and recorded on the blockchain</p>
            </div>

            <CircularProgressbarWithChildren value={this.state.timer} counterClockwise={true}>
              <img className="chainbolt" src="/assets/ChainBolt.png" alt="Chain bolt"/>
            </CircularProgressbarWithChildren>
          </div>
      </div>
    </div>

    );
  }
}

function mapStateToProps(state) {
  return {
    router: state.router
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      scoreUpdated: () => push('/quiz/results')
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatingComponent);
