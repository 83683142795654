import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }

  renderNav() {
    let navLinkReference = [];
    if (this.props.navigationBar.length !== 0) {
      this.props.navigationBar.forEach((item, index) => {
        if (item.path === "logo") {
          navLinkReference.push(
            <div key={item.title} className="nav-item logo">
              <Link to={"/"}>
                <img src="/assets/PowerChainLogo.png" />
              </Link>
            </div>
          );
        } else {
          navLinkReference.push(
            <div key={item.title} className="nav-item">
              <Link to={item.path}>{item.title}</Link>
            </div>
          );
        }
      });
    }
    return navLinkReference;
  }

  renderSideNav() {
    const show = this.state.menu ? "show" : "";

    return (
      <div className="side_wrapper">
        <nav className="navbar navbar-dark bg-dark">
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={"collapse navbar-collapse " + show}>
            <div className="navbar-nav">
            <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleMenu}
          >
            <span className="navbar-toggler-icon open"></span>
          </button>
              <Link onClick={e => {
                this.setState({menu: false});
              }} className="nav-item nav-link active" to="/">
                Home <span className="sr-only">(current)</span>
              </Link>
              <Link onClick={e => {
                this.setState({menu: false});
              }} className="nav-item nav-link" to="/account">
                Account
              </Link>
              <Link onClick={e => {
                this.setState({menu: false});
              }} className="nav-item nav-link" to="/powerboard">
                Leaderboard
              </Link>
              <Link onClick={e => {
                this.setState({menu: false});
              }} className="nav-item nav-link" to="/contact">
                Contact
              </Link>
            </div>
          </div>
        </nav>
      </div>
    );
  }

  renderPowercoin() {
    if (this.props.powercoin > 0) {
      return (
        <div className="powercoin">
          <img src="/powercoin-fav.png" alt="PowerCoin" />
          <span className="count">{this.props.powercoin}</span>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="nav-container">
        <div className="navNarrow">{this.renderSideNav()}</div>
        <div className="navWide">{this.renderNav()}</div>

        <div className="powercoin-count">{this.renderPowercoin()}</div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    navigationBar: [
      { title: "Home", path: "/" },
      { title: "Account", path: "/account" },
      { title: "<img src='/assets/PowerChainLogo.png' />", path: "logo" },
      { title: "Leaderboard", path: "/powerboard" },
      { title: "Contact", path: "/contact" }
    ],
    powercoin: state.quiz.powercoinCount
  };
}

export default connect(
  mapStateToProps,
  {}
)(TopNav);
