import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore, compose, applyMiddleware } from 'redux';
import {hydrate, render} from "react-dom";


import thunk from 'redux-thunk';

// import { rootSaga } from './actions';

import rootReducer from './reducers';

import App from './App';

import './css/bootstrap.min.css';
import './css/index.scss';

const createHistory = require("history").createBrowserHistory;

export const history = createHistory();

const middleware = [thunk, routerMiddleware(history)];

const store = createStore(
  rootReducer(history),
  compose(applyMiddleware(...middleware), window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f)
);

const rootElement = document.getElementById("root");
if(rootElement.hasChildNodes()){
  hydrate(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
    , rootElement
  )
} else {
  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
    , rootElement);
}
