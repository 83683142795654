import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";

class Result extends Component {


  render() {
    let powerval = (this.props.powerscore/1000).toFixed(2) == this.props.powerscore/1000 ? this.props.powerscore/1000 :  (this.props.powerscore/1000).toFixed(2) ;
    return (
    <div className="result-container">
      <Helmet>
        <title>Your PowerScore | PowerChain</title>
        <meta
          name="description"
          content="This is just a sample description for the homepage"
        />
      </Helmet>
      <div className="main-content">
        <div className="score-container">Presenting your
        <span className="perpetual-name">Perpetual</span>
        <div className="score-inner-container">
          <span className="powerscore-text">Power<br/>Score</span>
          <div className="score-amount">
            <img src="/assets/PowerScoreMeter.png" alt=""/>
            <span className="score">{powerval}</span>
          </div>
        </div>
        </div>
        <p className="score-container paragraph">How do you compare to the rest?</p>
        <Link className="btn btn-primary btn-orange" to="/powerboard">Leaderboard</Link>
      </div>
    </div>

    );
}
}

function mapStateToProps(state) {
  return {
    router: state.router,
    powerscore: state.quiz.powercoinCount
  };
}

export default connect(
  mapStateToProps,
  null
)(Result);
