import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Helmet } from "react-helmet";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { push } from 'react-router-redux';

import { getAccounts, createTransaction } from '../../reducers/web3';

class WalletOptions extends Component {


  invokeMetamask() {
    this.props.getAccounts().then(() => {
      if(!this.props.profile.tokensAllocated && !this.props.metamaskError){
        this.props.createTransaction();
      }
    });
  }

  render() {
    return (
      <div className="container login-container quiz-container">
        <div className="main-content">
        <Helmet>
          <title>Wallet Options | PowerChain</title>
          <meta
            name="description"
            content="This is just a sample description for the homepage"
          />
        </Helmet>
          <div className="login">
              <div className="option-container question-container">
                <h1 className="main-title">Before we create your account, how do you want to manage your Power Coin?</h1>
                <div className="multiple-btns">
                  <div className="metamask-container">
                    <div
                    className="btn btn-orange btn-standard"
                    tabIndex="0"
                    onKeyPress={event => {
                         if (event.key === 'Enter') {
                           this.invokeMetamask();
                         }
                       }}
                    onClick={event => {
                      event.preventDefault();
                      this.invokeMetamask()}}>
                      Metamask
                    </div>
                    <div className="error-text" style={{"display": this.props.metamaskError ? "block" : "none"}}>
                      Please install <a className="bold-inline" href="https://metamask.io/" target="_blank">metamask</a> and allow connection to the app.
                    </div>
                  </div>
                  <div
                  className="btn btn-orange btn-standard"
                  tabIndex="0"
                  onKeyPress={event => {
                       if (event.key === 'Enter') {
                         this.props.goToSubmitWallet();
                       }
                     }}
                  onClick={event => {
                    event.preventDefault();
                    this.props.goToSubmitWallet()}}>
                    Provide Address
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  metamaskError: state.web3.error,
  profile: state.user.profile
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAccounts,
      createTransaction,
      goToSubmitWallet: () => push("/register/add-wallet")
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletOptions);
