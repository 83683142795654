import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { userLogOutRequest } from '../../reducers/user';

class Account extends Component {

  componentDidUpdate(prevProps){
    console.log(this.props);

  }

  logOut() {
    this.props.userLogOutRequest();
  }


  render() {
    let powerval = (this.props.powerscore/1000).toFixed(2) == this.props.powerscore/1000 ? this.props.powerscore/1000 :  (this.props.powerscore/1000).toFixed(2) ;

    return (
  <div className="outer-container">
    <div className="container account-container quiz-container">
    <Helmet>
      <title>My Account | PowerChain</title>
      <meta
        name="description"
        content="This is just a sample description for the homepage"
      />
    </Helmet>
      <div className="main-content">
        <h1>Hi {this.props.profile.firstName} {this.props.profile.lastName ? this.props.profile.lastName.split('')[0] : ''}!</h1>
        <div className="score-container account">
          <div className="score-inner-container">
            <span className="powerscore-text">Power<br/>Score</span>
            <div className="score-amount">
              <img src="/assets/PowerScoreMeter.png" alt=""/>
              <span className="score">{powerval}</span>
            </div>
          </div>
          <div className="divider"/>
          <div className="score-inner-container powercoin-account-container">
            <span className="powercoin-text">Power <br/>Coin <br/>Balance</span>
            <div className="score-amount">
              <img src="/powercoin-fav.png" alt="PowerCoin"/>
              <span className="">{this.props.powerscore}</span>
            </div>
          </div>
        </div>
        <div
        tabIndex="0"
        onKeyPress={event => {
             if (event.key === 'Enter') {
               this.logOut();
             }
           }}
        onClick={this.logOut.bind(this)} className="btn btn-primary btn-orange hidden">Log out</div>
      </div>
    </div>
    <div className="learn-more-container">
      <div className="learn-more-text">
        <span>Follow the links below to learn about more ways you can reduce your carbon footprint.</span>
      </div>
      <a target="_blank" rel="noopener noreferrer" href="https://www.nyserda.ny.gov/Residents-and-Homeowners/Reduce-Your-Footprint" className="learn-more-icon large">
        <img src="/assets/CleanChoiceLogo.png" alt="Clean Choice NYC"/>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.nycgovparks.org/opportunities/volunteer" className="learn-more-icon">
        <img src="/assets/NYCParks.png" alt="NYC Parks logo"/>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.coned.com/en/save-money/energy-saving-programs" className="learn-more-icon large">
        <img src="/assets/ConEd.png" alt="ConEd logo"/>
      </a>
    </div>
  </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    router: state.router,
    user: state.user,
    profile: state.user.profile,
    powerscore: state.quiz.powercoinCount
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      userLogOutRequest
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
