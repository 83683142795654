import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Helmet } from "react-helmet";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { addManualWallet, createTransaction } from '../../reducers/web3';

class AddWallet extends Component {
  state = {
    showLabel1: true
  }

  onSubmit(e) {
    e.preventDefault();
    let wallet = e.target.querySelector("input[name='wallet']").value;
    this.props.addManualWallet(wallet).then(() => {
      if(!this.props.profile.tokensAllocated){
        this.props.createTransaction();
      }
    })
  }


  render() {
    return (
      <div className="container login-container quiz-container">
      <Helmet>
        <title>Add Wallet Info | PowerChain</title>
        <meta
          name="description"
          content="This is just a sample description for the homepage"
        />
      </Helmet>
      <div className="main-content">
        <div className="login wallet-container">
            <form onSubmit={this.onSubmit.bind(this)}>
            <h1 className="main-title">Please provide your wallet address below</h1>
              <div className="login-fieldgroup wallet-field">

                <label style={{"display": this.state.showLabel1 ? "block":"none"}}>Wallet Address</label>
                <input
                  type="text"
                  name="wallet"
                  id="wallet"
                  onChange={e => {
                    if(e.currentTarget.value){
                      this.setState({...this.state, showLabel1: false});
                    } else {
                      this.setState({...this.state, showLabel1: true});
                    }
                  }}
                  required
                />
              </div>

              <div className="sign-in text-center">
                  <button className="btn btn-primary btn-orange">Add Wallet</button>
              </div>
            </form>
        </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.user.profile
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addManualWallet,
      createTransaction
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddWallet);
