import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Helmet } from "react-helmet";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { userLoginRequest } from '../../reducers/user';

class Login extends Component {
  state = {
    showLabel1: true,
    showLabel2: true
  }

  onSubmit(e) {
    e.preventDefault();
    let email = e.target.querySelector("input[name='email']").value;
    let password = e.target.querySelector("input[name='password']").value;
    this.props.userLoginRequest(email, password);
  }


  render() {
    return (
      <div className="container login-container">
      <Helmet>
        <title>Log In | PowerChain</title>
        <meta
          name="description"
          content="This is just a sample description for the homepage"
        />
      </Helmet>
        <div className="login">
          <h1 className="text-center register-title">Sign in</h1>
            <form onSubmit={this.onSubmit.bind(this)}>
              <div className="login-fieldgroup">
                <label style={{"display": this.state.showLabel1 ? "block":"none"}}>Email Address</label>
                <input
                  type="email"
                  name="email"
                  id="exampleEmail"
                  onChange={e => {
                    if(e.currentTarget.value){
                      this.setState({...this.state, showLabel1: false});
                    } else {
                      this.setState({...this.state, showLabel1: true});
                    }
                  }}
                  required
                />
              </div>
              <div className="login-fieldgroup">
                <label style={{"display": this.state.showLabel2 ? "block":"none"}}>Password</label>
                <input
                  type="password"
                  name="password"
                  id="examplePassword"
                  onChange={e => {
                    if(e.currentTarget.value){
                      this.setState({...this.state, showLabel2: false});
                    } else {
                      this.setState({...this.state, showLabel2: true});

                    }
                  }}
                  required
                />
              </div>
              <div className="sign-in text-center">
                  <button className="btn btn-primary btn-orange">Let me in</button>
              </div>
                  <div className="text-center">
                    <Link className="register-link" to='/register'>Sign Up</Link>
                  </div>
            </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      userLoginRequest
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
