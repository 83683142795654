import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { fetchCurrentUser } from './reducers/user';
import { getAccounts } from './reducers/web3';

import TopNav from "./components/TopNav";
import PageIndex from "./components/PageIndex";
import ContactIndex from "./components/ContactIndex";

import QuizIndex from "./components/Quiz/QuizIndex";
import CalculatingComponent from "./components/Quiz/Calculating";
import Result from "./components/Quiz/Result";

import DemoLandingIndex from "./components/DemoLandingIndex";
import DemoIndex from "./components/Quiz/DemoIndex";

import Leaderboard from "./components/Leaderboard/LeaderboardIndex";

import Completed from "./components/CompletedIndex";

import Login from "./components/User/Login";
import Register from "./components/User/Register";
import WalletOptions from "./components/User/WalletOptions";
import AddWallet from "./components/User/AddWallet";

import Account from "./components/User/Account";

import ReactGA from 'react-ga';

ReactGA.initialize('UA-148393188-1');


class App extends Component {

  constructor(props) {
    super(props);
    ReactGA.pageview(window.location.pathname + window.location.search);

  }

  componentDidMount() {
    this.props.fetchCurrentUser();
    // if(this.props.web3.accounts == ''){
      // this.props.getAccounts();
    // }
  }

  render() {
      return (
        <div className="router-container">
        <TopNav />
        <Switch>
          <Route
          exact
          path="/"
          render={(props) => {
            return <PageIndex {...this.props} {...props} />
          }}
           />
           <Route
           exact
           path="/demo"
           render={(props) => {
             return <DemoLandingIndex {...this.props} {...props} />
           }}
            />
           <Route
           exact
           path="/contact"
           component={ContactIndex}
            />
          <Route
            path="/quiz/:quizIndex/:subpartIndex"
            render={ (props) => {
              if(this.props.router.action == "PUSH"){
                return <QuizIndex {...this.props} {...props} />
              } else {
                return <Redirect to="/" />;

              }
            }}
            />
          <Route
            exact
            path="/quiz/calculate"
            component={CalculatingComponent}
             />
          <Route
            exact
            path="/quiz/results"
            component={Result}
             />
           <Route
             path="/demo/quiz/:quizIndex/:subpartIndex"
             render={ (props) => {
                 return <DemoIndex {...this.props} {...props} />
             }}
             />
           <Route
             exact
             path="/demo/quiz/calculate"
             component={CalculatingComponent}
              />
           <Route
             exact
             path="/demo/quiz/results"
             component={Result}
              />
          <Route
             exact
             path="/powerboard"
             component={Leaderboard}
              />
          <Route
              exact
              path="/account"
              render={props => {
                if (
                  this.props.user.userId
                ) {
                  return <Account {...props} {...this.props} />;
                } else {
                  return <Redirect to="/account/login" />;
                }
              }}
            />
          <Route
            exact
            path="/account/login"
            render={props => {
                return <Login {...props} {...this.props} />;

            }}
             />
          <Route
           exact
           path="/register"
           render={props => {
             if (
               this.props.user.userId
             ) {
               return <Redirect to="/account" />;
             } else {
               return <Register {...props} {...this.props} />;
             }
           }}
            />
          <Route
            exact
            path="/register/wallet-options"
            component={WalletOptions}
             />
          <Route
           exact
           path="/register/add-wallet"
           component={AddWallet}
            />
          <Route
             exact
             path="/next-steps"
             component={Completed}
              />
        </Switch>
        </div>
      );
  }
}
function mapStateToProps(state) {
  return {
    router: state.router,
    user: state.user,
    web3: state.web3,
    quiz: state.quiz
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCurrentUser,
      getAccounts
    },
    dispatch
  );

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
