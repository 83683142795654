/**
** The QuizObject is used to generate the questions for our initial survey. The top level keys are the main topics for each section of the quiz. Within those values are objects for each question in the section. The keys for each of these objects equate to the subpath within the top level object, so the path will be /topic-key/subtopic-key/ . The pathName parameter should specify this path. The questionTitle is the display title for the question. The options parameter has an array for multiple choice answers. Each option should have a name, value, and specify where the user should go if the answer is selected in the nextPath parameter.
**/
export default  {
  "electricity": {
    "a":  {
        "pathName": "/electricity/a",
        "questionTitle":"Do you usually switch the lights off when you leave the house?",
        "options":
          [
            {"name": "Yes", "value": 1500, "nextPath": "/water/a"},
            {"name": "No", "value": 750, "nextPath": "/water/a"}
          ]
      },
    "displayName": "Electricity"
  },
  "water": {
      "a": {
          "pathName": "/water/a",
          "questionTitle":"Do you prefer drinking tap or bottled water?",
          "options":
            [
              {"name": "Tap", "value": 1500, "nextPath": "/temp-control/a"},
              {"name": "Bottled", "value": 750, "nextPath": "/temp-control/a"}
            ]
        },
      "displayName": "Water"
  },
  "temp-control": {
    "a": {
        "pathName": "/temp-control/a",
        "questionTitle":"Have you ever weatherized your house by sealing any air leaks or insulating areas of your home?",
        "options":
          [
            {"name": "Yes", "value": 1500, "nextPath": "/commute/a"},
            {"name": "No", "value": 750, "nextPath": "/commute/a"}
          ]
      },
      "displayName": "Temperature"

  },
  "commute": {
    "a": {
        "pathName": "/commute/a",
        "questionTitle":"How do you usually commute?",
        "options":
          [
            {"name": "Walking", "value": 1500, "nextPath": "/programs/a"},
            {"name": "Biking", "value": 1500, "nextPath": "/programs/a"},
            {"name": "Public Transport", "value": 750, "nextPath": "/programs/a"},
            {"name": "Car", "value": 500, "nextPath": "/programs/a"}
          ]
      },
      "displayName": "Commute"
  },
  "programs": {
    "a": {
        "pathName": "/programs/a",
        "questionTitle":"Have you ever participated in any NYC Gov programs?",
        "options":
          [
            {"name": "Yes", "value": 1500, "nextPath": "/calculate"},
            {"name": "No", "value": 750, "nextPath": "/calculate"}
          ]
      },
      "displayName": "Programs"
  }
}
