import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import Cookies from 'js-cookie';

import { push } from 'connected-react-router';

const initialState = {
  powercoinCount: Cookies.get("powercoinCount") ? Cookies.get("powercoinCount") : 0,
  answers: {
    "electricity": {},
    "water": {},
    "temp-control": {},
    "commute": {},
    "programs": {}
  },
  currentPath: Cookies.get('currentPath') ? Cookies.get('currentPath') : '/quiz/electricity/a'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "POWERCOIN_UPDATE":
      Cookies.set("powercoinCount", action.payload);
      return {
        ...state,
        powercoinCount: action.payload
      };

    case "ANSWERS_UPDATE":
      if(state.answers[action.payload.mainTopic] == undefined){
        state.answers[action.payload.mainTopic] = {};
      }
      state.answers[action.payload.mainTopic][action.payload.subTopic] = action.payload.answer;
      Cookies.set("answers", JSON.stringify(state.answers));
      return {
        ...state
      }

    case "UPDATE_PATH":
      Cookies.set('currentPath', action.payload);
      return {
        ...state,
        currentPath: action.payload
      }

    case "SET_USER":
      return {
        answers: action.payload.profile.answers ? action.payload.profile.answers : {
          "electricity": {},
          "water": {},
          "temp-control": {},
          "commute": {},
          "programs": {}
        },
        currentPath: action.payload.profile.currentPath ? action.payload.profile.currentPath : "/quiz/electricity/a",
        powercoinCount: action.payload.profile.powercoinCount
      }

    case "LOGGED_OUT":
      return {
        powercoinCount: 0,
        answers: {
          "electricity": {},
          "water": {},
          "temp-control": {},
          "commute": {},
          "programs": {}
        },
        currentPath: '/quiz/electricity/a'
      }

    default:
      return state;
  }
};


/*********************************************************************
 ** Function powercoinUpdate
 **
 ** @param: amount = The amount to update by
 ** @param: current = The current powercoin amount
 **
 *********************************************************************/

export const powercoinUpdate = (amount, current) => {
  return dispatch => {
      if(Cookies.get("uid")){
        firebase
          .firestore()
          .collection('users')
          .doc(Cookies.get("uid"))
          .update({"powercoinCount": parseInt(amount)+parseInt(current)})
          .then(() => {
            dispatch({ type: "POWERCOIN_UPDATE", payload: parseInt(amount)+parseInt(current) });
          });
      } else {
        dispatch({ type: "POWERCOIN_UPDATE", payload: parseInt(amount)+parseInt(current) });
      }
  };
};

export const updatePath = (currentPath) => {
  return dispatch => {
      if(Cookies.get("uid")){
        firebase
          .firestore()
          .collection('users')
          .doc(Cookies.get("uid"))
          .update({"currentPath": `/quiz${currentPath}`})
          .then(() => {
            dispatch({ type: "UPDATE_PATH", payload: `/quiz${currentPath}` });
            dispatch(push(`/quiz${currentPath}`));

          });
      } else {
        dispatch({ type: "UPDATE_PATH", payload: `/quiz${currentPath}` });
        dispatch(push(`/quiz${currentPath}`));

      }
  };
};

export const updateDemoPath = (currentPath) => {
  return dispatch => {
      if(Cookies.get("uid")){
        firebase
          .firestore()
          .collection('users')
          .doc(Cookies.get("uid"))
          .update({"currentPath": `/demo/quiz${currentPath}`})
          .then(() => {
            dispatch({ type: "UPDATE_PATH", payload: `/demo/quiz${currentPath}` });
            dispatch(push(`/demo/quiz${currentPath}`));

          });
      } else {
        dispatch({ type: "UPDATE_PATH", payload: `/demo/quiz${currentPath}` });
        dispatch(push(`/demo/quiz${currentPath}`));

      }
  };
};


export const answerUpdate = (answer, mainTopic, subTopic) => {
  return dispatch => {
        if(Cookies.get("uid")){
          let updateObj = {}
          updateObj[subTopic] = answer;
          firebase
            .firestore()
            .collection('users')
            .doc(Cookies.get('uid'))
            .collection('answers')
            .doc(mainTopic)
            .update(updateObj)
            .then(() => {
              dispatch({ type: "ANSWERS_UPDATE", payload: {mainTopic: mainTopic, subTopic: subTopic, answer: answer} });
            })
            .catch(err => {
              firebase
                .firestore()
                .collection('users')
                .doc(Cookies.get('uid'))
                .collection('answers')
                .doc(mainTopic)
                .set(updateObj)
                .then(() => {
                  console.log("updated????");
                  dispatch({ type: "ANSWERS_UPDATE", payload: {mainTopic: mainTopic, subTopic: subTopic, answer: answer} });
                })
            })
        } else {
          dispatch({ type: "ANSWERS_UPDATE", payload: {mainTopic: mainTopic, subTopic: subTopic, answer: answer} });
        }
  };
};
